/*this file is used whenever an image needs to be displayed on screen.
it will hide the image until it has been fully loaded from its source, showing a placeholder grey box.
once the image has been fully loaded from source, it removes the placeholder element and populates the image.
this avoids the ugly look of watching an image load line by line vertically. */

import React, {useState} from "react";

import NRImagePlaceholder from "./NRImagePlaceholder";

function NRImageLoader (props){
    /* create a state tracker initialized to false, indicating if the image has been loaded completely yet */
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    return (
        <div>
            {!isImageLoaded && <NRImagePlaceholder />}
            <img className={props.classes}
                src={props.src}
                alt={props.alt}
                style = { isImageLoaded? {} : {display: "none"} }
                onLoad={ () => setIsImageLoaded(true)}
            />
        </div>
    )
}

export default NRImageLoader
