import NRHomePageCarousel from "./NRHomePageCarousel";
import NRAboutUs from "./NRAboutUs";
import NRServices from "./NRServices";
import NRContactUs from "./NRContactUs";

function Home(){
    return (
        <div>
            <NRHomePageCarousel />
            <hr className="separator" />
            <NRAboutUs />
            <hr className="separator" />
            <NRServices />
            <hr className="separator" />
            <NRContactUs />
        </div>
    )
}

export default Home;
