
import NRImageLoader from "./NRImageLoader";

function NRContactUs(){
    return(
        <section className="section-formatting dark-section contact-section" id="contact">
            <div className="contact-us-container">
                <NRImageLoader key="1"
                    classes="contact-us-image drop-shadows"
                    src="https://i.imgur.com/cOKqxQl.jpg"
                    alt="barn door image"
                />
                <div className="contact-us-text">
                    <h1>Contact Us</h1>
                    <div className="p">Email:<br />Contact@NolteRemodeling.com</div>
                    <div className="p">Phone (Earl Nolte):<br />(856) 912-0467</div>
                </div>
            </div>
        </section>
    );
};

export default NRContactUs;
