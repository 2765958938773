import React from "react"

//get current year for copyright
var today = new Date();
var thisYear = today.getFullYear();

function NRFooter(){
    return (
        <footer className="white-section">
            <h6>Nolte Remodeling Copyright {thisYear}</h6>
            <h6>NJHIC 13VH 11074000</h6>
            <h6>Website by DLB</h6>
        </footer>
    )//return value
}//function

export default  NRFooter;
