
import NRImageLoader from "./NRImageLoader";

function ServiceCard(props){
    return (
        <div className="col-xl-3 col-md-6 col-lg-6 col-sm-12 service-tile-shell">
            <div className="services-box drop-shadows red-card-border h-100">
                <NRImageLoader key={props.cardID}
                    classes="service-image card-img-top"
                    src={props.imageSource}
                    alt={props.imageAltText}
                />
                <div className="card-body">
                    <h3 className="service-title card-title">{props.cardTitle}</h3>
                    <div className="p service-description card-text">{props.cardDescription}</div>
                </div>
            </div>
        </div>
    );
};

export default ServiceCard;
