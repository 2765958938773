import React from "react"
import ContentLoader from "react-content-loader"

const ImagePlaceholder = (props) => (
  <ContentLoader
    speed={0}
    width={110}
    height={110}
    viewBox="0 0 110 110"
    backgroundColor="#e8e8e8"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="0" ry="0" width="110" height="110" />
  </ContentLoader>
)

export default ImagePlaceholder
