/* this function returns the complete set of tags to be used in the home screen menu.
    Only add items to this list which you want to see in the Gallery menu */
export function getImageTags(){
    return (
        ["All", "Bars", "Basements", "Bathrooms"
        , "Custom Cabinetry", "Kitchens",  "Other"]
    )
}

const ImageList = [
    {
        /* Laura's Kitchen */
        id: 1
        , sourceURL: "https://i.imgur.com/pNnrjuR.jpg"
        , orientation:"landscape"
        , title: "Kitchen Remodel"
        , alt: "Kitchen remodel image"
        , caption: ""
        , tags:["homeCarousel"
                , "Kitchens"
                , "All"]
    }
    , {
        /* Barrel sink bathroom */
        id: 2
        , sourceURL: "https://i.imgur.com/whan46a.jpg"
        , orientation:"landscape"
        , title: "Bathroom Remodel"
        , alt: "Bathroom remodel image"
        , caption: ""
        , tags: ["homeCarousel"
                , "Bathrooms"
                , "All"]
    }
    , {
        /* foyer-landing built-in */
        id: 3
        , sourceURL: "https://i.imgur.com/67viWuy.jpg"
        , orientation:"landscape"
        , title: "Built-in Cabinetry"
        , alt: "Built-in cabinetry image"
        , caption: ""
        , tags: ["homeCarousel"
                , "Custom Cabinetry"
                , "All"]
    }
    , {
        /* fireplace, stone surround */
        id: 4
        , sourceURL: "https://i.imgur.com/ciLR03v.jpg"
        , orientation:"landscape"
        , title: "Fireplace Remodel"
        , alt: "Fireplace remodel image"
        , caption: "Custom stone fireplace"
        , tags: ["homeCarousel"
                , "Basements"
                , "Other"
                , "All"]
    }
    , {
        /* playhouse facade built into wall */
        id: 5
        , sourceURL: "https://i.imgur.com/ohqZ78n.jpg"
        , orientation:"landscape"
        , title: "Playhouse"
        , alt: "Playhouse image"
        , caption: "Under-staircase play house"
        , tags: ["homeCarousel"
                , "Basements"
                , "Other"
                , "All"]
    }
    , {
        /* basement with stonework walls and wood floor */
        id: 6
        , sourceURL: "https://i.imgur.com/LBxJGZz.jpg"
        , orientation:"landscape"
        , title: "Basement Remodel"
        , alt: "Basement remodel image"
        , caption: ""
        , tags: ["homeCarousel"
                , "Basements"
                , "All"]
    }
    , {
        /* basement bar with woodwork ceiling and poker table */
        id: 7
        , sourceURL: "https://i.imgur.com/MBtSiiD.jpg"
        , orientation:"landscape"
        , title: "Basement Remodel"
        , alt: "Basement remodel image"
        , caption: ""
        , tags: ["Basements"
                , "Bars"
                , "All"]
    }
    , {
        /* Bathroom with large built-in, tile floors, and chandalier */
        id: 8
        , sourceURL: "https://i.imgur.com/r48nQln.jpg"
        , orientation:"landscape"
        , title: "Bathroom Remodel"
        , alt: "Bathroom remodel image"
        , tags: ["Bathrooms"
                , "Custom Cabinetry"
                , "All"]
    }
    , {
        /* Penberthy LBI Kitchen with large island  */
        id: 9
        , sourceURL: "https://i.imgur.com/Mmk8I8P.jpg"
        , orientation:"landscape"
        , title: "Kitchen Remodel"
        , alt: "Kitchen remodel image"
        , caption: ""
        , tags: ["Kitchens"
                , "All"]
    }
    , {
        /* Dark double barn doors from basement remodel  */
        id: 10
        , sourceURL: "https://i.imgur.com/cOKqxQl.jpeg"
        , orientation:"landscape"
        , title: "Barn Doors"
        , alt: "Barn doors image"
        , caption: "Custom fabricated barn doors"
        , tags: ["Other"
                , "All"]
    }
    , {
        /* Bathroom weith square glass shower  */
        id: 11
        , sourceURL: "https://i.imgur.com/jryqOKK.jpg"
        , orientation:"landscape"
        , title: "Bathroom Remodel"
        , alt: "Bathroom remodel image"
        , caption: ""
        , tags: ["Bathrooms"
                , "All"]
    }
    , {
        /* Bathroom weith free-standing tub  */
        id: 12
        , sourceURL: "https://i.imgur.com/LRELRvX.jpg"
        , orientation:"landscape"
        , title: "Bathroom Remodel"
        , alt: "Bathroom remodel image"
        , caption: ""
        , tags: ["Bathrooms"
                , "All"]
    }
    , {
        /* Basement wide shot with half-wall  */
        id: 13
        , sourceURL: "https://i.imgur.com/kgYNALY.jpg"
        , orientation:"landscape"
        , title: "Basement Finish"
        , alt: "Basement finsh image"
        , caption: ""
        , tags: ["Basements"
                , "All"]
    }
    , {
        /* FHOP outside shot  */
        id: 14
        , sourceURL: "https://i.imgur.com/sXKRL1z.jpg"
        , orientation:"landscape"
        , title: "Pro Bono"
        , alt: "Chapel exterior image"
        , caption: "Outdoor Chapel at Francis House of Prayer 'pro bono'"
        , tags: ["Other"
                , "All"]
    }
    , {
        /* Bathroom 2-sink unit stone countertop  */
        id: 15
        , sourceURL: "https://i.imgur.com/eWzymkp.jpg"
        , orientation:"landscape"
        , title: "Bathroom Remodel"
        , alt: "Bathroom remodel image"
        , caption: ""
        , tags: ["Bathrooms"
                , "All"]
    }
    , {
        /* Navy blue walls bathroom */
        id: 16
        , sourceURL: "https://i.imgur.com/dPz5oN4.jpg"
        , orientation:"portrait"
        , title: "Bathroom Remodel"
        , alt: "Bathroom remodel image"
        , caption: ""
        , tags: ["Bathrooms"
                , "All"]
    }
    , {
        /* FHOP Chapel interior */
        id: 17
        , sourceURL: "https://i.imgur.com/9sI8Add.jpg"
        , orientation:"portrait"
        , title: "Pro Bono"
        , alt: "Chapel interior image"
        , caption: "Outdoor Chapel at Francis House of Prayer 'pro bono'"
        , tags: ["Other"
                , "All"]
    }
    , {
        /* Finished basement exercise area */
        id: 18
        , sourceURL: "https://i.imgur.com/gA0hc9v.jpg"
        , orientation:"landscape"
        , title: "Basement Finish"
        , alt: "Finshed basement image"
        , caption: ""
        , tags: ["Basements"
                , "All"]
    }
    , {
        /* Basement Bar side angle */
        id: 19
        , sourceURL: "https://i.imgur.com/JrS9ypp.jpg"
        , orientation:"landscape"
        , title: "Basement Bartop"
        , alt: "Finshed basement image"
        , caption: ""
        , tags: ["Basements"
                , "Bars"
                , "All"]
    }
    , {
        /* Tile shower stall image, inset tile shelf alcoves */
        id: 20
        , sourceURL: "https://i.imgur.com/13SCKVA.jpg"
        , orientation:"portrait"
        , title: "Shower Remodel"
        , alt: "Bathroom remodel image"
        , caption: "Walk-in shower"
        , tags: ["Bathrooms"
                , "All"]
    }
    , {
        /* Basement bar front angle */
        id: 21
        , sourceURL: "https://i.imgur.com/0ZgcCyQ.jpg"
        , orientation:"landscape"
        , title: "Basement Bartop"
        , alt: "Finished basement image"
        , caption: ""
        , tags: ["Basements"
                , "Bars"
                , "All"]
    }
    , {
        /* Bathroom double sink 2 mirrors above */
        id: 22
        , sourceURL: "https://i.imgur.com/PMIqJL7.jpg"
        , orientation:"landscape"
        , title: "Bathroom Vanity"
        , alt: "Bathroom vanity image"
        , caption: ""
        , tags: ["Bathrooms"
                , "All"]
    }
    , {
        /* Finished basement TV hung on wall */
        id: 23
        , sourceURL: "https://i.imgur.com/Wl5uCw7.jpg"
        , orientation:"landscape"
        , title: "Basement Finish"
        , alt: "Finished basement image"
        , caption: ""
        , tags: ["Basements"
                , "All"]
    }
    , {
        /* Basement bedroom */
        id: 24
        , sourceURL: "https://i.imgur.com/u4Oobg1.jpg"
        , orientation:"landscape"
        , title: "Basement Bedroom"
        , alt: "Finished basement bedroom image"
        , caption: ""
        , tags: ["Basements"
                , "All"]
    }
    , {
        /* Basement with ship lap walls, fireplace and tv */
        id: 25
        , sourceURL: "https://i.imgur.com/IGjBHfr.jpg"
        , orientation:"landscape"
        , title: "Basement lounge"
        , alt: "Finished basement image"
        , caption: ""
        , tags: ["Basements"
                , "All"]
    }
    , {
        /* Basement nursery */
        id: 26
        , sourceURL: "https://i.imgur.com/ogRBH36.jpg"
        , orientation:"landscape"
        , title: "Basement nursery"
        , alt: "Finished nursery image"
        , caption: ""
        , tags: ["Basements"
                , "All"]
    }
    , {
        /* Basement kitchen */
        id: 27
        , sourceURL: "https://i.imgur.com/5YUBQGG.jpg"
        , orientation:"landscape"
        , title: "Basement kitchen"
        , alt: "Finished basement kitchen image"
        , caption: ""
        , tags: ["Basements"
                , "homeCarousel"
                , "All"]
    }
    , {
        /* Basement nursery moon lamp */
        id: 28
        , sourceURL: "https://i.imgur.com/7pPWj6p.jpg"
        , orientation:"portrait"
        , title: "Basement nursery"
        , alt: "Finished basement nursery image"
        , caption: ""
        , tags: ["Basements"
                , "All"]
    }
    , {
        /* Somer kitchen */
        id: 29
        , sourceURL: "https://i.imgur.com/NHrFvEz.jpg"
        , orientation:"landscape"
        , title: "Kitchen Remodel"
        , alt: "Kitchen remodel image"
        , caption: ""
        , tags: ["Kitchens"
                , "All"]
    }
    , {
        /* Old white illuminated built-in */
        id: 30
        , sourceURL: "https://i.imgur.com/kFzEOOX.jpg"
        , orientation:"landscape"
        , title: "Built-in cabinetry"
        , alt: "Built-in cabinetry image"
        , caption: ""
        , tags: ["Custom Cabinetry"
                , "All"]
    }
    , {
        /* Old light wood trapezoid cabinet  */
        id: 31
        , sourceURL: "https://i.imgur.com/dYVHPD4.jpg"
        , orientation:"portrait"
        , title: "Built-in cabinetry"
        , alt: "Built-in cabinetry image"
        , caption: ""
        , tags: ["Custom Cabinetry"
                , "All"]
    }
    , {
        /* Old teal cabinet  */
        id: 32
        , sourceURL: "https://i.imgur.com/rDFweSO.jpg"
        , orientation:"landscape"
        , title: "Built-in cabinetry"
        , alt: "Built-in cabinetry image"
        , caption: ""
        , tags: ["Custom Cabinetry"
                , "All"]
    }
    , {
        /* MaGrann bar  */
        id: 33
        , sourceURL: "https://i.imgur.com/z0QIRaL.jpg"
        , orientation:"landscape"
        , title: "Basement Bar"
        , alt: "Basement bar image"
        , caption: ""
        , tags: ["Basements"
                , "Bars"
                , "All"]
    }

];

export default ImageList;

export function getImagesByTag (searchTag){
    /* pull back all images matching the requested tag */
    var allImagesUnsorted =  ImageList.filter(
        function ( imageEntry ){
            return imageEntry.tags.find( tag => tag == searchTag ) !== undefined;
        }
    );

    /* separate out all images that are landscape orientation */
    var landscapeImages = allImagesUnsorted.filter(
        function ( imageEntry ){
            return imageEntry.orientation == "landscape";
        }
    );

    /* separate out all images that are portrait orientation */
    var portraitImages = allImagesUnsorted.filter(
        function ( imageEntry ){
            return imageEntry.orientation == "portrait";
        }
    );

    /* combine landscape and portrait in that order as the return, so all portrait images go together and last. */
    var allImages = landscapeImages.concat(portraitImages);

    return allImages;
};

export function getImageByID (searchID){
    return (
        ImageList.find( image => image.id == searchID )
    )
};
