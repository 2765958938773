import React from "react"
import {Navbar, NavDropdown, Container, Nav} from "react-bootstrap";

import {getImageTags} from "../ImageList.js"
import NRImageLoader from "./NRImageLoader";

function CreateGalleryMenuItem( description ){
    const link = "/Gallery/" + description;
    return (
        <NavDropdown.Item href={link} className="nav-link-text">
            <span className="nav-link-text">{description}</span>
        </NavDropdown.Item>
    )
}

function NRHeader(){
    {/*Generate a list of image categories to populate the Gallery Menu items */}
    const ImageTagList = getImageTags();

    return (
        <Navbar bg="light" expand="lg" className=" white-section header-bar" sticky="top">
          <Container>
              <Navbar.Brand href="/#home">
                  <NRImageLoader key="1"
                      classes="align-top logo-image"
                      src="https://i.imgur.com/e6mY9UC.jpg"
                      alt="Nolte Remodeling logo"
                  />
                <div className="p logo-subtitle">(856) 912-0467</div>
              </Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
              <Nav className="me-auto" className="justify-content-end">

                <Nav.Link href="/#home" className="nav-link-text">
                    <span className="nav-link-text">Home</span>
                </Nav.Link>

                <Nav.Link href="/#about-us" className="nav-link-text">
                    <span className="nav-link-text">About</span>
                </Nav.Link>

                <Nav.Link href="/#services" className="nav-link-text">
                    <span className="nav-link-text">Services</span>
                </Nav.Link>

                <Nav.Link href="/#contact" className="nav-link-text">
                    <span className="nav-link-text">Contact</span>
                </Nav.Link>

                <NavDropdown title="Galleries" id="basic-nav-dropdown" className="nav-link-text">
                    {ImageTagList.map(CreateGalleryMenuItem)}
                </NavDropdown>

              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

    ); //return value end
};//function NRHeader

export default NRHeader;


/*
    --------------------------  old bootstrap classic code --------------------------
<div>
    <nav className="navbar navbar-expand-lg header-bar white-section fixed-top bg-light">
        <div className="container header-container">
            <div className="navbar-brand">
                <a href="../public/index.html">
                    <img src="images/Logos/nr-logo-full.png" alt="Nolte Remodeling Logo" className="logo-image" />
                </a>

                <div className="p">(856) 912-0467</div>
            </div>

            <button className="navbar-toggler navbar-light" type="button" data-toggle="collapse" data-target="#navBar-link-menu" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navBar-link-menu">
                <ul class="navbar-nav ml-auto navbar-dropdown-list">
                    <li className="nav-item">
                        <a href="#home" className="nav-link">Home</a>
                    </li>
                    <li className="nav-item">
                        <a href="#about-us" className="nav-link">About</a>
                    </li>
                    <li className="nav-item">
                        <a href="#services" className="nav-link">Services</a>
                    </li>
                    <li className="nav-item">
                        <a href="#contact" className="nav-link">Contact</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

</div>
*/
