import React from "react";
import ServiceCard from "./ServiceCard.jsx"

function NRServices(){
    return(
        <section className="section-formatting services-section" id="services">
            <div className="container-fluid services-row row">

                <ServiceCard
                    cardID="1"
                    imageSource="https://i.imgur.com/CSt3DzY.jpg"
                    imageAltText="Blueprint image"
                    cardTitle="Design and Estimation"
                    cardDescription="Share with us your vision and ideas. We utilize our experience across hundreds of successful projects to help you realize your dreams, while working with you to keep the project on budget with no hidden costs."
                />

                <ServiceCard
                    cardID="2"
                    imageSource="https://i.imgur.com/03tCQBA.jpg"
                    imageAltText="schedule image"
                    cardTitle="Project Management"
                    cardDescription="We manage all aspects of your project from conception to completion, including skilled hands-on construction, procuring all materials, hiring and supervising subcontractor specialists as required, filing necessary permits, and scheduling inspections."
                />

                <ServiceCard
                    cardID="3"
                    imageSource="https://i.imgur.com/H5pw91c.jpg"
                    imageAltText="Clean up image"
                    cardTitle="Professionalism and Courtesy"
                    cardDescription="Our policy is to treat your property with respect. We will provide floor and dust coverings as required, clean the site of all debris each day and do our very best to minimize the disruption to you and your family throughout the process."
                />

                <ServiceCard
                    cardID="4"
                    imageSource="https://i.imgur.com/zrlxcbc.jpg"
                    imageAltText="Communication image"
                    cardTitle="Accessibility"
                    cardDescription="We will keep you informed as to the progress and timelines of the project. Our high standard of communication means all questions will be answered and messages responded to on a daily basis. As owner-operators we are present on the job every day ensuring our direct availability to you."
                />

            </div>
        </section>
    );
};

export default NRServices;
