//get modular components
import NRHeader from "./components/NRHeader";
import NRFooter from "./components/NRFooter";
import { Outlet } from "react-router-dom";

//generate output from this file
function App(){
    return (
        <div>
            <NRHeader />
            <div className="page-container">
                <Outlet /> {/* this is where child routes will be rendered in the page, sourced from the router in index.js */}
            </div>
            <NRFooter />
        </div>
    );//app return
};//app function


//export output when this component is called
export default App;
